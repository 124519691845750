import React from "react";

import "./header.css";
import NavBar from "../navbar";

const Header = () => (
    <NavBar/>
);

export default Header;
